import { Dispatch } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { parseSafely, queryStringToString } from '@hooks/search';
import { getLocationHash } from '@utils/url/getLocationHash';
import { PageDefaultValue, SortDefaultValue } from '../../../constants/boardDefaultParams';

export interface BoardsSearchParameters {
  page: number;
  sort: string;
  searchTerm: string;
}

export const useQueryStringBoards = (): [BoardsSearchParameters, Dispatch<BoardsSearchParameters>] => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParamsObj = {
    ...{
      page: PageDefaultValue,
      sort: SortDefaultValue,
      searchTerm: '',
    },
    ...Object.fromEntries([...searchParams]),
  } as {
    page: string;
    pageSize: string;
    sort?: string;
    searchTerm: string;
  };

  const { page, sort, searchTerm } = searchParamsObj;

  const setState: Dispatch<BoardsSearchParameters> = (value) => {
    const data: Record<string, string | string[]> = {
      page: value.page.toString(),
      sort: value.sort,
      searchTerm: value.searchTerm,
    };

    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(data)}`,
        hash: getLocationHash(),
      },
      {
        replace: true,
      },
    );
  };

  return [
    {
      page: parseSafely(page, 1),
      sort: queryStringToString(sort) ?? SortDefaultValue,
      searchTerm,
    },
    setState,
  ];
};
