import { useQueryClient } from '@tanstack/react-query';
import { useIsPhone } from '@components/responsive';
import { LIVE_BOARDS_QUERY_KEY } from '@queries/sharedKeys';
import {
  FREE_TRACKING_BOARD_INFO_QUERY_KEY,
  FREE_TRACKING_BOARDS_QUERY_KEY,
  useCreateFreeTrackingBoardApi,
  useUpdateFreeTrackingBoardApi,
} from '@services/LiveBoardsService';
import { Mode } from '@services/ShipmentDataGatewayService';
import {
  featureAnalyticsAddMoreShipmentsSuccess,
  featureAnalyticsStartTrackingSuccess,
} from '../analytics/freeTrackingPageAnalytics';
import { FreeTrackingCarriersRequireFreeTrial } from '../constants';
import { postBoardLinkToParentWindow } from '../utils';

function useAddShipmentSuccessCallback() {
  const isPhone = useIsPhone();
  return (boardPath: string | undefined | null) => {
    if (boardPath) {
      const boardUrl = PLATFORM_HREF + boardPath;
      if (!isPhone) {
        postBoardLinkToParentWindow(boardUrl, false);
      } else {
        // window.open(boardUrl, '_blank');
        postBoardLinkToParentWindow(boardUrl, false);
      }
    }
  };
}

export function useCreateFreeTrackingBoard(mode: Mode) {
  const queryClient = useQueryClient();
  const onAddShipmentSuccess = useAddShipmentSuccessCallback();
  return useCreateFreeTrackingBoardApi({
    options: {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [FREE_TRACKING_BOARDS_QUERY_KEY],
        });
        if (data && data.createFreeTrackingBoard) {
          const { boardLink } = data.createFreeTrackingBoard;
          onAddShipmentSuccess(boardLink);
          featureAnalyticsStartTrackingSuccess(mode);
        }
      },
    },
  });
}

export function useUploadFreeTrackingExtraShipmentsToBoard(mode: Mode) {
  const queryClient = useQueryClient();
  const onAddShipmentSuccess = useAddShipmentSuccessCallback();
  return useUpdateFreeTrackingBoardApi({
    options: {
      onSuccess: (data, variables) => {
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: [FREE_TRACKING_BOARD_INFO_QUERY_KEY],
          });
          queryClient.removeQueries({
            queryKey: [LIVE_BOARDS_QUERY_KEY],
          });
        }, 1000);
        if (data && data.uploadFreeTrackingExtraShipmentsToBoard) {
          const { boardLink } = data.uploadFreeTrackingExtraShipmentsToBoard;
          onAddShipmentSuccess(boardLink);
          let shipmentLength = 'length' in variables.shipments ? variables.shipments.length : 1;
          featureAnalyticsAddMoreShipmentsSuccess(mode, shipmentLength);
        }
      },
    },
  });
}

export function useCarrierListForFreeTrial() {
  return FreeTrackingCarriersRequireFreeTrial;
}
