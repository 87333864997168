import { graphql } from '../../../generated/gql';

export const roadCsvQuery = graphql(/* GraphQL */ `
  query roadCargoCsv(
    $page: Int! = 0
    $size: Int! = 20
    $criteria: [SearchCriteria!]
    $macros: [GqlMacro!]
    $sort: [SearchSortCriteria!]
  ) {
    searchCargos(page: $page, size: $size, criteria: $criteria, macros: $macros, sort: $sort) {
      roadCsv
    }
  }
`);
