import {
  Cargo,
  CargoStatus,
  Label,
  LocationType,
  Mode,
  ShipmentReferenceTypeCode,
} from '@services/ShipmentDataGatewayService/generated/graphql';

export const oceanCargoEditDrawerFixture: Cargo = {
  id: 'bcn1.ti.cargo.5dd07aa4-7dca-4b14-8f0d-a3351309ce9c',
  carrierShipmentId: 'something-something-id-something',
  createdAt: '2022-02-02T00:00:00Z',
  status: CargoStatus.InTransit,
  customerId: 'example-customer',
  carrier: { displayName: 'OOCL', nmftaCode: 'OOLU', mode: Mode.Ocean },
  freshnessTimestamp: '2023-04-26T17:14:28Z',
  mode: Mode.Ocean,
  documents: [],
  documentCount: 0,
  errors: [],
  shipmentReferences: [
    {
      id: 'bcn1.ti.shipment-ref.8929d3eb-db89-42e9-a844-0afdbe5f7fae',
      type: ShipmentReferenceTypeCode.Po,
      value: 'PO-1',
      purchaseOrderId: 'bcn1.po.purchase-order.mocked-version-1',
    },
  ],
  slimPurchaseOrders: [
    {
      id: 'bcn1.po.purchase-order.mocked-version-1',
      poNumber: 'PO-1',
      supplierName: 'Ronaldo 1 Lta',
      documents: [],
      isWatched: false,
      labels: [],
      slimSkus: [],
      version: 'v1',
      documentCount: 0,
    },
  ],
  container: { containerNumber: 'CSNU7039494', isoCode: '' },
  transportSummary: {
    postPrimaryDischarge: [],
    prePrimaryLoad: [],
    transshipments: [],
    postPrimaryDischargeLocations: [],
    prePrimaryLoadLocations: [],
    transshipmentLocations: [],
    primaryDischarge: {
      location: {
        id: '',
        name: 'Felixstowe, UK',
        type: LocationType.Facility,
        geoLocation: { lat: 51.963355, lon: 1.3277187 },
        unLocation: {
          code: 'FXT',
        },
      },
      milestoneViews: {
        full: [],
        summary: [],
      },
    },
    primaryLoad: {
      location: {
        id: '',
        name: 'Yantian',
        type: LocationType.Facility,
        geoLocation: { lat: 22.575, lon: 114.2765 },
        unLocation: {
          code: 'CNYTN',
        },
      },
      milestoneViews: {
        full: [],
        summary: [],
      },
    },
    currentVehicle: {
      id: 'some-vehicle-id',
      displayName: 'Some Vessel',
      vehicleEvents: [],
      mode: Mode.Ocean,
      identification: { imoNumber: '9365788' },
    },
    haulagePairExists: false,
  },
  labels: [
    { id: 'bcn1.l.label.1696506c-227a-448e-a0e4-7f10efd8c94c', name: 'Epic stuff', customerId: 'customer-id' },
  ] as Label[],
  demurrageInfo: null,
  cargoEvents: [],
  transports: [],
  milestones: [],
  customFields: [],
};
