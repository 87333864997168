import { ContainerOrPurchaseOrderView, TrackingPageContextType } from '@components/tracking/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- intentionally
const noopAny = () => undefined as any;

export const initialTrackingPageCtx: TrackingPageContextType = {
  mode: undefined,
  containerOrPurchaseOrderView: ContainerOrPurchaseOrderView.CONTAINERS,
  page: 1,
  pageSize: 20,
  searchKeyword: '',
  filters: [],
  notStatuses: [],
  portOfDischarge: [],
  portOfLoading: [],
  transhipmentPorts: [],
  carriers: [],
  vessels: [],
  suppliers: [],
  labels: [],
  flightNumber: [],
  relativeDate: '',
  startDate: '',
  endDate: '',
  sort: 'arrivalsDesc',
  milestone: '',
  numberOfItemsFound: 0,
  etaChangedFilter: false,
  watchlistFilter: false,
  demurrageFilter: false,
  isDaysAtTsPortOn: false,
  routingDirect: false,
  routingIndirect: false,
  daysAtTsPort: 0,
  demurrageValue: 1,
  daysOffQuayFilter: false,
  daysOffQuayValue: 0,
  setContainerOrPurchaseOrderView: noopAny,
  setPage: noopAny,
  setPageSize: noopAny,
  setSearchKeyword: noopAny,
  setPageAndSize: noopAny,
  setFilters: noopAny,
  setPortOfDischarge: noopAny,
  setPortOfLoading: noopAny,
  setOrigin: noopAny,
  setDestination: noopAny,
  setWarehouses: noopAny,
  setCarriers: noopAny,
  setVessels: noopAny,
  setRelativeDate: noopAny,
  setStartDate: noopAny,
  setEndDate: noopAny,
  setMilestone: noopAny,
  setCargoReadyDate: noopAny,
  setSort: noopAny,
  setNumberOfItemsFound: noopAny,
  setEtaChangedFilter: noopAny,
  setRoutingDirect: noopAny,
  setTranshipmentPorts: noopAny,
  setRoutingIndirect: noopAny,
  setWatchlistFilter: noopAny,
  setSuppliers: noopAny,
  setLabels: noopAny,
  userView: 'search-results',
  setUserView: noopAny,
  setDaysAtTsPort: noopAny,
  setMode: noopAny,
  setDisplayMode: noopAny,
  displayMode: undefined,
  setDaysOnQuay: noopAny,
  setFlightNumber: noopAny,
  setDaysOffQuay: noopAny,
};
