import { getGraphqlClient } from '@features/api';
import { graphql } from '../../../generated/gql';
import { AggregatedFilterDataRequest } from '../../../generated/graphql';
import { endpoint } from '../../endpoint';

export const aggregatedFilterDataQuery = graphql(/* GraphQL */ `
  query aggregatedFilterDataV2($request: AggregatedFilterDataRequest!) {
    aggregatedFilterDataV2(request: $request) {
      labels {
        id
        value
      }
      carriers {
        id
        value
      }
      suppliers {
        id
        value
      }
      vehicles {
        id
        value
      }
      flightNumbers {
        id
        value
      }
      originLocations {
        id
        value
        country
        portCode
      }
      primaryLoadLocations {
        id
        value
        country
        portCode
      }
      transshipmentLocations {
        id
        value
        country
        portCode
      }
      primaryDischargeLocations {
        id
        value
        country
        portCode
      }
      destinationLocations {
        id
        value
        country
        portCode
      }
      warehouseLocations {
        id
        value
      }
    }
  }
`);

interface FilterDataQueryParams {
  request: AggregatedFilterDataRequest;
}

export const getAggregatedFilterData = async (params: FilterDataQueryParams) => {
  const client = await getGraphqlClient(endpoint);
  const data = await client.request(aggregatedFilterDataQuery, { ...params });

  return data.aggregatedFilterDataV2;
};
