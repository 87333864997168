import * as Sentry from '@sentry/react';
import { ClientError } from 'graphql-request';
import { GraphQLClientResponse } from '@features/api/graphql/types';
import { isBoardNonExistentError, isBoardsPermissionDeniedError } from '../../../boards/services/errors';

export const boardsGQLMiddleware = (response: GraphQLClientResponse<unknown> | ClientError | Error) => {
  let captureException = false;
  if (response instanceof Error || response instanceof ClientError) {
    if (response instanceof ClientError) {
      captureException = !isBoardsPermissionDeniedError(response) && !isBoardNonExistentError(response);
    }
    if (captureException) {
      Sentry.captureException(response);
    }
  }
};
