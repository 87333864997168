import { PropsWithChildren } from 'react';
import { useBreakpoint } from 'use-breakpoint';
import { BREAKPOINTS } from '@constants/mediaQueries';

export function Phone({ children }: PropsWithChildren<Record<string, unknown>>) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  if (breakpoint === 'phone') {
    return children;
  }
  return null;
}
