import { additionalFieldsFixture } from '@features/additionalFields';
import { CargoStatus, DistanceUnit, Mode } from '@services/ShipmentDataGatewayService/generated/graphql';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@utils/common/dates/formatCargoDateTimeView';
import { mockMilestones } from './cargoMilestone';
import { ContextCargoCardProps, MultipleLocationsFullMilestones } from '../types';

const additionalFieldsInitialData = {
  isExpanderOpen: false,
  handleExpanderClose: () => {},
  additionalFieldsData: additionalFieldsFixture,
  additionalFieldsAddedByText: '',
  showAdditionalFieldsValues: true,
};

export const prePrimaryLoadDataWithPreAndOriginExample: MultipleLocationsFullMilestones = [
  {
    locationName: 'Empty container pick-up: Shanghai rail yard',
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
  {
    locationName: 'Origin: Factory',
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
];

export const CargoCardPropsAirExample: ContextCargoCardProps = {
  cargoId: 'bcn1.ti.cargo.c2fb6abd-a66b-47ab-b112-7242ba00889d',
  cargoNumber: 'ABCDEF1234',
  beaconNumber: 'beacon-123',
  cargoStatus: CargoStatus.Confirmed,
  carrierShipmentId: 'CARRIER123', // Added missing property
  cargoMode: Mode.Air,
  disableCargoNumberLink: true,
  disablePOLink: false,
  currentMode: Mode.Air,
  vehicleName: 'BA 478',
  carrierCode: 'BA',
  licensePlate: '',
  trailerPlate: '',
  supplierName: 'Supplier name',
  prePrimaryLoad: [],
  primaryLoad: {
    id: '',
    displayName: 'Shenzhen Airport',
    code: 'SZX',
    country: 'China',
    summaryMilestones: mockMilestones.milestonesSummary.EtdAndEtaSimple,
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
  primaryDischarge: {
    id: '',
    displayName: 'London Heathrow',
    country: 'UK',
    code: 'LHR',
    summaryMilestones: mockMilestones.milestonesSummary.EtdAndEtaSimple,
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
  postPrimaryDischarge: [],
  destination: {
    id: '',
    displayName: '',
    country: '',
    code: '',
    summaryMilestones: [],
    fullMilestones: [],
    fullAddress: '',
  },
  daysOffQuay: 0,
  isDaysOffQuayChipGreyedOut: false,
  isDaysOffQuayEnabled: false,
  isDemurrageChipGreyedOut: false,
  highlightText: '',
  distanceRemaining: {
    unit: DistanceUnit.Unknown,
    value: 0,
  },
  distanceTotal: {
    unit: DistanceUnit.Unknown,
    value: 0,
  },
  onEditClick: () => null,
  daysOnQuay: 0,
  transhipments: [
    { name: 'Dubai airport', code: 'DXB', milestones: mockMilestones.milestonesSummary.EtdAndEtaSimple, isAtTs: true },
    { name: 'Odessa International Airport', code: 'ODS', milestones: mockMilestones.milestonesSummary.EtdAndEtaSimple },
  ],
  freshnessTime: '4h ago',
  poData: [
    { id: '1', name: 'FTRW75887-Po009_IRBFT 12' },
    { id: '2', name: 'FTRW75887-Po009_IRBFT' },
    { id: '3', name: 'PO3' },
    { id: '4', name: 'PO4' },
  ],
  currentSplit: 2,
  totalSplits: 5,
  labels: [
    { name: 'Label 1', id: 'lbl-1' },
    { name: 'Label 2', id: 'lbl-2' },
    { name: 'Label 3', id: 'lbl-3' },
    { name: 'Label 4', id: 'lbl-4' },
  ],
  isWatched: true,
  dateFormat: DATE_TIME_FORMAT,
  disableWatchlist: false,
  disableAddPO: false,
  pieces: 2,
  newEtaOffset: '',
  haulagePairExists: false,
  isNewEtd: false,
  isNewEta: false,
  cargoCustomFields: [],
  ...additionalFieldsInitialData,
};

export const CargoCardPropsRoadExample: ContextCargoCardProps = {
  cargoId: 'bcn1.ti.cargo.c2fb6abd-a66b-47ab-b112-kjasd34jqanja',
  cargoNumber: 'ABG32432',
  cargoStatus: CargoStatus.InTransit,
  beaconNumber: 'beacon-123',
  carrierShipmentId: 'CARRIER-123', // Added missing property
  currentMode: Mode.Road,
  vehicleName: 'OFD6225',
  carrierCode: 'Hammond Transport',
  licensePlate: 'LI-C3-N5',
  trailerPlate: 'TR-AI-LE',
  cargoMode: Mode.Road,
  supplierName: 'Supplier name',
  prePrimaryLoad: [],
  primaryLoad: {
    id: '',
    displayName: 'Felixstowe',
    code: 'GBFXT',
    country: 'United Kingdom',
    summaryMilestones: mockMilestones.milestonesSummary.EtdAndEtaSimple,
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
  primaryDischarge: {
    id: '',
    displayName: 'Leicester',
    country: 'UK',
    code: 'LEUK',
    summaryMilestones: mockMilestones.milestonesSummary.EtdAndEtaSimple,
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
  postPrimaryDischarge: [],
  destination: {
    id: '',
    displayName: '',
    country: '',
    code: '',
    summaryMilestones: [],
    fullMilestones: [],
    fullAddress: '',
  },
  daysOffQuay: 0,
  isDaysOffQuayChipGreyedOut: false,
  isDaysOffQuayEnabled: false,
  isDemurrageChipGreyedOut: false,
  highlightText: '',
  distanceRemaining: {
    unit: DistanceUnit.Km,
    value: 5,
  },
  distanceTotal: {
    unit: DistanceUnit.Km,
    value: 7,
  },
  onEditClick: () => null,
  daysOnQuay: 0,
  freshnessTime: '6h ago',
  poData: [
    { id: '1', name: 'FTRW75887-Po009_IRBFT 12' },
    { id: '2', name: 'FTRW75887-Po009_IRBFT' },
    { id: '3', name: 'PO3' },
    { id: '4', name: 'PO4' },
  ],
  labels: [
    { name: 'Label 1', id: 'lbl-1' },
    { name: 'Label 2', id: 'lbl-2' },
    { name: 'Label 3', id: 'lbl-3' },
    { name: 'Label 4', id: 'lbl-4' },
  ],
  isWatched: true,
  dateFormat: DATE_FORMAT,
  disableWatchlist: false,
  disableAddPO: false,
  pieces: 0,
  newEtaOffset: '',
  isNewEtd: false,
  isNewEta: false,
  disableCargoNumberLink: false,
  disablePOLink: false,
  totalSplits: 0,
  currentSplit: 0,
  transhipments: [],
  haulagePairExists: false,
  cargoCustomFields: [],
  ...additionalFieldsInitialData,
};

export const CargoCardPropsOceanExample: ContextCargoCardProps = {
  cargoId: 'bcn1.ti.cargo.c2fb6abd-a66b-47ab-b112-7242ba00889e',
  cargoNumber: 'MSKU1532127',
  cargoStatus: CargoStatus.BerthedAtDest,
  beaconNumber: 'beacon-123',
  carrierShipmentId: '', // Added missing property
  currentMode: Mode.Ocean,
  vehicleName: 'MSC LIVORNO',
  carrierCode: 'MSCU',
  licensePlate: '',
  trailerPlate: '',
  cargoMode: Mode.Ocean,
  supplierName: 'Supplier name',
  prePrimaryLoad: [],
  daysOffQuay: 0,
  isDaysOffQuayChipGreyedOut: false,
  isDaysOffQuayEnabled: false,
  primaryLoad: {
    id: '',
    displayName: 'Felixstowe',
    code: 'GBFXT',
    country: 'China',
    summaryMilestones: mockMilestones.milestonesSummary.EtdAndEtaSimple,
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
  primaryDischarge: {
    id: '',
    displayName: 'Auckland',
    country: 'UK',
    code: 'AUCKL',
    summaryMilestones: mockMilestones.milestonesSummary.EtdAndEtaSimple,
    fullMilestones: mockMilestones.milestonesInFull.EtdAndEtaSimple,
  },
  postPrimaryDischarge: [],
  destination: {
    id: '',
    displayName: '',
    country: '',
    code: '',
    summaryMilestones: [],
    fullMilestones: [],
    fullAddress: '',
  },
  distanceRemaining: {
    unit: DistanceUnit.Unknown,
    value: 0,
  },
  distanceTotal: {
    unit: DistanceUnit.Unknown,
    value: 0,
  },
  isDemurrageChipGreyedOut: false,
  highlightText: '',
  onEditClick: () => null,
  daysOnQuay: 0,
  freshnessTime: '4h ago',
  poData: [
    { id: '1', name: 'FTRW75887-Po009_IRBFT 12' },
    { id: '2', name: 'FTRW75887-Po009_IRBFT' },
    { id: '3', name: 'PO3' },
    { id: '4', name: 'PO4' },
  ],
  labels: [
    { name: 'Label 1', id: 'lbl-1' },
    { name: 'Label 2', id: 'lbl-2' },
    { name: 'Label 3', id: 'lbl-3' },
    { name: 'Label 4', id: 'lbl-4' },
  ],
  isWatched: true,
  dateFormat: DATE_FORMAT,
  disableWatchlist: false,
  disableAddPO: false,
  pieces: 0,
  newEtaOffset: '',
  isNewEtd: false,
  isNewEta: false,
  disableCargoNumberLink: false,
  disablePOLink: false,
  totalSplits: 0,
  currentSplit: 0,
  transhipments: [],
  haulagePairExists: false,
  cargoCustomFields: [],
  ...additionalFieldsInitialData,
};

export const NewlyCreatedCargoCardPropsOceanExample: ContextCargoCardProps = {
  ...CargoCardPropsOceanExample,
  isFreeTrackingEntity: true,
};
