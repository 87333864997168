import { useState } from 'react';
import { Box, IconSvg, Modal, Text } from '@beacon-devops/components';
import { useNavigate } from 'react-router-dom';
import { FlexContainer } from '@components/common';
import { Desktop, Phone, useIsPhone } from '@components/responsive';
import { useLiveBoardContext } from '@features/boards';
import {
  DEFAULT_BOARD_INFO_VALUE,
  useFreeTrackingBoardSelector,
} from '@features/boards/selectors/useFreeTrackingBoardInfoSelector';
import { HubSpotSignupFormModal, HubspotSignupTextButton } from '@features/freeTracking';
import { featureAnalyticsStartTrackingClick } from '@features/freeTracking/analytics/freeTrackingPageAnalytics';
import { AddExtraShipmentsForm } from '@features/freeTracking/components/addFreeTrackingShipments/AddExtraShipmentsForm';
import {
  getUploadExtraShipmentsToBoardPayload,
  translateCreateBoardGqlErrorsToUiError,
} from '@features/freeTracking/components/startTrackingForm/utils';
import { ModeSpecificText } from '@features/freeTracking/constants';
import { useUploadFreeTrackingExtraShipmentsToBoard } from '@features/freeTracking/pages/hooks';
import { useCarrierListSelector } from '../../selectors/useCarrierListSelector';

export function AddFreeTrackingShipmentsModal() {
  const { data: carrierList = [] } = useCarrierListSelector();
  const navigate = useNavigate();
  const {
    boardData: { mode, boardId, boardPath },
  } = useLiveBoardContext();
  const { data: { remainingShipments } = DEFAULT_BOARD_INFO_VALUE } = useFreeTrackingBoardSelector(boardId, true);
  const closeModal = () => navigate(boardPath);

  const {
    mutate: uploadFreeTrackingExtraShipmentsToBoardAPI,
    isSuccess: uploadExtraShipmentsSuccessful,
    error: uploadExtraShipmentsError,
  } = useUploadFreeTrackingExtraShipmentsToBoard(mode);

  const isPhone = useIsPhone();
  const errors = translateCreateBoardGqlErrorsToUiError([uploadExtraShipmentsError]);
  const [isSigninUp, setIsSigninUp] = useState(false);

  const remainingShipmentsMarkup = (
    <FlexContainer mb={isPhone ? 5 : 0}>
      <Text as="span" fontSize={2}>
        {`${remainingShipments} ${ModeSpecificText[mode].cargoAddMoreLabel} left`}
      </Text>
      <HubspotSignupTextButton
        text="Sign up to get 10 more free"
        title="Track 20 shipments with 165+ carriers for free"
        boardId={boardId}
        textButtonProps={{ fontSize: 2, fontFamily: 'regular' }}
      />
    </FlexContainer>
  );

  const Title = (
    <>
      <FlexContainer justifyContent="space-between" alignItems="center" mb={5}>
        <Text fontSize={5} fontFamily="semibold">{`Add ${ModeSpecificText[mode].cargoAddMoreLabel}`}</Text>
        <FlexContainer alignItems="center">
          <Desktop>{remainingShipmentsMarkup}</Desktop>
          <Box onClick={closeModal} style={{ cursor: 'pointer' }}>
            <IconSvg name="close" size="24px" />
          </Box>
        </FlexContainer>
      </FlexContainer>
      <Phone>{remainingShipmentsMarkup}</Phone>
    </>
  );

  return (
    <>
      <Modal
        isOpen
        overrideTitle={Title}
        closeButtonVariant="none"
        width={isPhone ? '100%' : '640px'}
        shouldCloseOnOverlayClick
        p={isPhone ? '24px' : '32px'}
      >
        <FlexContainer flexDirection="column">
          <AddExtraShipmentsForm
            carrierList={carrierList}
            handleStartTracking={(shipmentDetails) => {
              uploadFreeTrackingExtraShipmentsToBoardAPI(
                getUploadExtraShipmentsToBoardPayload(shipmentDetails, mode, boardId),
              );
              featureAnalyticsStartTrackingClick(mode, true, shipmentDetails.shipments);
            }}
            boardIdForHubspotSignup={boardId}
            mode={mode}
            updateBoardSuccessful={uploadExtraShipmentsSuccessful}
            remainingShipments={remainingShipments}
            errors={errors}
            handleCloseModal={closeModal}
          />
        </FlexContainer>
      </Modal>
      {boardId && (
        <FlexContainer display="flex">
          <HubSpotSignupFormModal
            isOpen={isSigninUp}
            close={() => setIsSigninUp(false)}
            title="Track 20 shipments with 165+ carriers for free"
            boardId={boardId}
          />
        </FlexContainer>
      )}
    </>
  );
}

export default AddFreeTrackingShipmentsModal;
