import { TrackingSearchParameters } from '@hooks/search';
import { Mode } from '@services/ShipmentDataGatewayService/generated/graphql';

export const initialTrackingSearchParams: TrackingSearchParameters = {
  page: 0,
  pageSize: 20,
  query: '',
  sort: '',
  startDate: '',
  endDate: '',
  filters: [],
  portOfDischarge: [],
  portOfLoading: [],
  carriers: [],
  vessels: [],
  selectedView: '',
  milestone: '',
  containerOrPurchaseOrderView: '',
  numberOfItemsFound: 0,
  etaChangedFilter: false,
  watchlistFilter: false,
  suppliers: [],
  demurrageFilter: false,
  demurrageValue: 0,
  daysOffQuayFilter: false,
  daysOffQuayValue: 0,
  mode: Mode.Ocean,
};
