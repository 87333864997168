import { subDays, formatISO, startOfDay } from 'date-fns';
import {
  Milestone,
  MilestoneType,
  ShipmentTransportStage,
} from '@services/ShipmentDataGatewayService/generated/graphql';

export const createMilestoneData = (milestoneOverrides?: Partial<Milestone>): Milestone => {
  return {
    type: MilestoneType.EtdAtPll,
    label: 'ETD',
    order: 1,
    otherDateInfo: [],
    dateInfo: {
      eventDateTime: {
        date: '',
        timestamp: '2022-08-05T12:00:00Z',
        zone: null,
      },
      sourceName: 'OOCL via Searates',
      lastFetched: '2022-08-05T09:00:00Z',
    },
    shipmentTransportStage: ShipmentTransportStage.Unknown,
    hasBeenAchieved: false,
    isStandalonePreviousDateMilestone: false,
    ...milestoneOverrides,
  };
};

const milestonesSummary: Record<string, Milestone[]> = {
  EtdAndEtaSimple: [
    createMilestoneData(),
    createMilestoneData({
      type: MilestoneType.EtaAtPdl,
      label: 'ETA',
      order: 2,
      dateInfo: {
        eventDateTime: {
          date: '2022-08-12',
          timestamp: null,
          zone: null,
        },
        sourceName: 'OOCL via Searates',
        lastFetched: '2022-08-05T07:00:00Z',
      },
    }),
  ],
  EtdAndEtaNew: [
    createMilestoneData({
      dateInfo: { lastFetched: new Date().toISOString(), eventDateTime: { timestamp: new Date().toISOString() } },
    }),
    createMilestoneData({
      label: 'ETA',
      type: MilestoneType.EtaAtPdl,
      dateInfo: { lastFetched: new Date().toISOString(), eventDateTime: { timestamp: new Date().toISOString() } },
    }),
  ],
  ETAPlusPreviousETA: [
    createMilestoneData({
      type: MilestoneType.EtaAtPdl,
      label: 'ETA',
      order: 1,
      dateInfo: {
        eventDateTime: {
          date: '2022-08-05',
          timestamp: null,
          zone: null,
        },
        sourceName: 'Hapag-Lloyd via SeaRates',
        lastFetched: formatISO(subDays(startOfDay(new Date('2023-10-19T12:00:00Z')), 5)),
      },
    }),
    createMilestoneData({
      type: MilestoneType.PreviousEtaAtPdl,
      label: 'Previous ETA',
      order: 2,
      dateInfo: {
        eventDateTime: {
          date: '',
          timestamp: '2022-08-03T14:21:00Z',
          zone: null,
        },
        sourceName: 'Hapag-Lloyd via SeaRates',
      },
      isStandalonePreviousDateMilestone: true,
    }),
  ],
};

const milestonesInFull: Record<string, Milestone[]> = {
  EtdAndEtaSimple: [
    ...milestonesSummary.EtdAndEtaSimple,
    createMilestoneData({
      label: 'Gated Out Empty',
      type: MilestoneType.GatedOutEmpty,
      order: 3,
      dateInfo: {
        eventDateTime: {
          date: '',
          timestamp: '2022-08-14T12:00:00Z',
          zone: null,
        },
      },
    }),
  ],
  ETAPlusPreviousETA: [
    ...milestonesSummary.ETAPlusPreviousETA,
    createMilestoneData({
      label: 'Gated Out Empty',
      type: MilestoneType.GatedOutEmpty,
      order: 3,
      dateInfo: null, // intentionally
    }),
  ],
  GroupFromMultipleSources: [
    {
      ...milestonesSummary.ETAPlusPreviousETA[0],
      otherDateInfo: [
        {
          order: 1,
          eventDateTime: {
            date: '2022-08-07',
            timestamp: null,
            zone: null,
          },
          sourceName: 'OOCL via Searates',
          lastFetched: formatISO(subDays(startOfDay(new Date('2023-10-19T12:00:00Z')), 2)),
        },
      ],
    },
    milestonesSummary.ETAPlusPreviousETA[1],
  ],
  ShowPreviousChanges: [createMilestoneData(), createMilestoneData({ order: 2, label: 'Loaded' })],
};

export const mockMilestones = {
  milestonesSummary,
  milestonesInFull,
};
