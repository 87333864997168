import {
  SearchCargosQuery,
  TransportSummaryLocationFragment,
} from '@services/ShipmentDataGatewayService/generated/graphql';
import { graphql } from '../../../generated/gql';

export const LocationFragment = graphql(/* GraphQL */ `
  fragment TransportSummaryLocation on LocationWithMilestones {
    location {
      id
      name
      facility {
        iataCode
      }
      unLocation {
        code
        countryName
      }
      address {
        address1
        city
        postcode
        country
      }
      geoLocation {
        lat
        lon
      }
    }
    milestoneViews {
      summary {
        type
        label
        dateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
        }
        hasBeenAchieved
      }
      full {
        type
        label
        order
        isStandalonePreviousDateMilestone
        hasBeenAchieved
        dateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
        otherDateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
      }
    }
  }
`);

// Removing milestone full reduced query time by 40% -> this can be loaded async. (0.48 -> 0.28s)
export const searchCargosQuery = graphql(/* GraphQL */ `
  query searchCargos(
    $page: Int! = 0
    $size: Int! = 20
    $criteria: [SearchCriteria!]
    $macros: [GqlMacro!]
    $sort: [SearchSortCriteria!]
  ) {
    searchCargos(page: $page, size: $size, criteria: $criteria, macros: $macros, sort: $sort) {
      page
      pageSize
      totalPages
      totalRecords
      totalOceanRecords
      totalAirRecords
      totalRoadRecords
      cargos {
        id
        errors {
          type
        }
        customFields {
          key
          value
        }
        carrierShipmentId
        status
        freshnessTimestamp
        mode
        numberOfPackages
        container {
          containerNumber
        }
        demurrageInfo {
          daysOnQuayPoD
          daysAtTsPort
          daysOffQuayDest
        }
        documents {
          id
          associatedId
          sizeBytes
          type
          name
          createdAt
          createdBy
          createdByUserId
          sizeBytes
        }
        createdAt
        createdByName
        labels {
          name
        }
        carrier {
          displayName
          nmftaCode
          iataCode
        }
        shipmentInfo {
          currentSplit
          totalSplits
        }
        shipmentReferences {
          id
          type
          value
          purchaseOrderId
        }
        slimPurchaseOrders {
          poNumber
          id
          supplierName
        }
        isWatched
        transportSummary {
          legs
          currentLeg
          haulagePairExists
          transportDistances {
            total {
              value
              unit
            }
            remaining {
              value
              unit
            }
          }
          currentJourneyNumber
          currentMode
          currentVehicle {
            displayName
            identification {
              licensePlate
            }
          }
          prePrimaryLoad {
            ...TransportSummaryLocation
          }
          origin {
            ...TransportSummaryLocation
          }
          primaryLoad {
            ...TransportSummaryLocation
          }
          transshipments {
            ...TransportSummaryLocation
          }
          primaryDischarge {
            ...TransportSummaryLocation
          }
          postPrimaryDischarge {
            ...TransportSummaryLocation
          }
          destination {
            ...TransportSummaryLocation
          }
          warehouse {
            ...TransportSummaryLocation
          }
        }
      }
    }
  }
`);

export type SearchCargosQueryResponse = SearchCargosQuery['searchCargos'];

export type SearchCargos = NonNullable<SearchCargosQueryResponse>['cargos'];

export type SearchCargo = SearchCargos extends (infer U)[] ? U : never;

export type TrackingPageCargoCardData = SearchCargos[0];

export type SearchShipmentReferences = NonNullable<SearchCargo>['shipmentReferences'];

export type SearchCarrier = NonNullable<SearchCargo>['carrier'];

export type SearchTransportSummaryLocation = NonNullable<TransportSummaryLocationFragment>['location'];

export type SearchTransportSummaryMilestoneViews = NonNullable<TransportSummaryLocationFragment>['milestoneViews'];

export type SearchTransportSummaryMilestoneViewsSummary = NonNullable<SearchTransportSummaryMilestoneViews>['summary'];

export type SearchTransportSummaryMilestoneViewsFull = NonNullable<SearchTransportSummaryMilestoneViews>['full'];

export type SearchTransportSummaryAddress = NonNullable<SearchTransportSummaryLocation>['address'];
