import {
  Cargo,
  CargoStatus,
  FacilityType,
  IShipmentReference,
  LocationType,
  Mode,
  ShipmentReferenceTypeCode,
} from '@services/ShipmentDataGatewayService/generated/graphql';
import { mockMilestones } from './cargoMilestone';

export const createShipmentReference = (overrides?: Partial<IShipmentReference>): IShipmentReference => ({
  id: '',
  type: ShipmentReferenceTypeCode.Mbol,
  value: 'MBOL value',
  purchaseOrderId: '',
  ...overrides,
});

/** easily create mocked cargos if needed by any tests, only specify props needed */
export const createMockCargo = (overrides?: Partial<Cargo>): Cargo => ({
  id: '201-90283061',
  status: CargoStatus.InTransit,
  createdAt: '2023-01-04T00:00:00Z',
  carrierShipmentId: '208-90123064',
  customerId: 'some-customer-id-1',
  documents: [],
  documentCount: 0,
  labels: [],
  slimPurchaseOrders: [],
  errors: [],
  container: {
    containerNumber: '207-90279064',
  },
  mode: Mode.Ocean,
  transportSummary: {
    transshipments: [],
    postPrimaryDischarge: [],
    prePrimaryLoad: [],
    postPrimaryDischargeLocations: [],
    transshipmentLocations: [],
    prePrimaryLoadLocations: [],
    primaryDischarge: {
      location: {
        id: '1234',
        name: 'Felixstowe',
        type: LocationType.Facility,
        geoLocation: { lat: 51.963355, lon: 1.3277187 },
        unLocation: {
          code: 'FXT',
        },
      },
      milestoneViews: {
        full: [],
        summary: [],
      },
    },
    primaryLoad: {
      location: {
        id: '6452',
        name: 'Auckland',
        type: LocationType.Facility,
        geoLocation: { lat: 51.963355, lon: 1.3277187 },
        unLocation: {
          code: 'FXT',
        },
      },
      milestoneViews: {
        full: [],
        summary: [],
      },
    },
    haulagePairExists: false,
  },
  transports: [],
  cargoEvents: [],
  milestones: [],
  shipmentReferences: [],
  customFields: [],
  ...overrides,
});

export const createRoadMockCargo = (overrides?: Partial<Cargo>): Cargo => ({
  id: 'bcn1.cargo-12jka8asda9s',
  status: CargoStatus.InTransit,
  labels: [],
  slimPurchaseOrders: [],
  createdAt: '2023-01-04T00:00:00Z',
  carrierShipmentId: 'CS-Ronaldo',
  customerId: 'some-customer-id-1',
  documents: [],
  documentCount: 0,
  errors: [],
  mode: Mode.Road,
  transportSummary: {
    postPrimaryDischarge: [],
    prePrimaryLoad: [],
    transshipments: [],
    postPrimaryDischargeLocations: [],
    transshipmentLocations: [],
    prePrimaryLoadLocations: [],
    currentVehicle: {
      id: 'vehicle-id',
      displayName: 'MUNKEBO MAERSK',
      identification: {
        licensePlate: 'RO-NAl-DO',
      },
      vehicleEvents: [],
      mode: Mode.Ocean,
    },
    primaryDischarge: {
      location: {
        name: 'London Warehouse',
        facility: {
          iataCode: 'LHR',
          type: FacilityType.Other,
        },
        type: LocationType.Facility,
        id: '12234234',
      },
      milestoneViews: {
        full: mockMilestones.milestonesInFull.ETAPlusPreviousETA,
      },
    },
    primaryLoad: {
      location: {
        name: 'Leicester Ronaldo Wh',
        facility: {
          iataCode: 'SZX',
          type: FacilityType.Other,
        },
        type: LocationType.Facility,
        id: '645234534',
      },
      milestoneViews: {
        full: mockMilestones.milestonesInFull.ETAPlusPreviousETA,
      },
    },
    haulagePairExists: false,
  },
  transports: [],
  cargoEvents: [],
  milestones: [],
  milestoneViews: {},
  shipmentReferences: [
    {
      id: 'sh-2',
      type: ShipmentReferenceTypeCode.TransportNumber,
      value: 'CR7-Ronaldo',
    },
    {
      id: 'sh-3',
      type: ShipmentReferenceTypeCode.TrailerPlate,
      value: 'Trailer-Ronaldo',
    },
    {
      id: 'sh-4',
      type: ShipmentReferenceTypeCode.BeaconReferenceNumber,
      value: 'Beacon-TP-Ronaldo',
    },
  ],
  customFields: [],
  ...overrides,
});

export const cargoList: Cargo[] = [
  createMockCargo({
    id: '201-90283061',
    status: CargoStatus.InTransit,
    createdAt: '2023-01-04T00:00:00Z',
    carrierShipmentId: '208-90123064',
    container: {
      containerNumber: '207-90279064',
    },
    mode: Mode.Ocean,
    transportSummary: {
      postPrimaryDischarge: [],
      prePrimaryLoad: [],
      transshipments: [],
      postPrimaryDischargeLocations: [],
      transshipmentLocations: [],
      prePrimaryLoadLocations: [],
      primaryDischarge: {
        location: {
          name: 'Felixstowe',
          type: LocationType.Facility,
          id: '1234',
        },
        milestoneViews: {
          full: mockMilestones.milestonesInFull.EtdAndEtaSimple,
        },
      },
      primaryLoad: {
        location: {
          name: 'Auckland',
          type: LocationType.Facility,
          id: '6452',
        },
        milestoneViews: {
          full: mockMilestones.milestonesInFull.EtdAndEtaSimple,
        },
      },
      haulagePairExists: false,
    },
    shipmentReferences: [],
    milestoneViews: {
      po: mockMilestones.milestonesSummary.EtdAndEtaSimple,
    },
    labels: [
      {
        customerId: 'customer-1',
        name: '0 - label with a long name',
        id: 'label0',
      },
      {
        customerId: 'customer-1',
        name: '1 - label with a long name',
        id: 'label1',
      },
      {
        customerId: 'customer-1',
        name: '2 - label with a long name',
        id: 'label2',
      },
      {
        customerId: 'customer-1',
        name: '3 - label with a long name',
        id: 'label3',
      },
    ],
  }),
  createMockCargo({
    id: '203-90830641',
    status: CargoStatus.InTransit,
    customerId: 'some-customer-id-2',
    createdAt: new Date().toISOString(),
    carrierShipmentId: '208-90123064',
    mode: Mode.Ocean,
    container: {
      containerNumber: '201-92279064',
    },
    transportSummary: {
      transshipments: [],
      postPrimaryDischarge: [],
      prePrimaryLoad: [],
      postPrimaryDischargeLocations: [],
      transshipmentLocations: [],
      prePrimaryLoadLocations: [],
      primaryDischarge: {
        location: {
          name: 'Montreal, QC',
          type: LocationType.Facility,
          id: '1234',
        },
        milestoneViews: {
          full: mockMilestones.milestonesInFull.EtdAndEtaSimple,
        },
      },
      primaryLoad: {
        location: {
          name: 'South Shields',
          type: LocationType.Facility,
          id: '6452',
        },
        milestoneViews: {
          full: mockMilestones.milestonesInFull.EtdAndEtaSimple,
        },
      },
      haulagePairExists: false,
    },
    milestones: [],
    shipmentReferences: [],
    milestoneViews: {
      po: mockMilestones.milestonesSummary.ETAPlusPreviousETA,
    },
    labels: [
      {
        customerId: 'customer-1',
        name: '8 - This is long as well',
        id: 'label8',
      },
    ],
  }),
  createMockCargo({
    id: '201-jk2342342kjmak',
    status: CargoStatus.InTransit,
    createdAt: '2023-03-03T00:00:00Z',
    mode: Mode.Air,
    transportSummary: {
      transshipments: [],
      postPrimaryDischarge: [],
      prePrimaryLoad: [],
      postPrimaryDischargeLocations: [],
      transshipmentLocations: [],
      prePrimaryLoadLocations: [],
      primaryDischarge: {
        location: {
          name: 'London Heathrow',
          facility: {
            iataCode: 'LHR',
            type: FacilityType.Airport,
          },
          type: LocationType.Facility,
          id: '1234',
        },
        milestoneViews: {
          full: mockMilestones.milestonesInFull.ETAPlusPreviousETA,
        },
      },
      primaryLoad: {
        location: {
          name: 'Shenzen International Airport',
          facility: {
            iataCode: 'SZX',
            type: FacilityType.Airport,
          },
          type: LocationType.Facility,
          id: '6452',
        },
        milestoneViews: {
          full: mockMilestones.milestonesInFull.ETAPlusPreviousETA,
        },
      },
      haulagePairExists: false,
    },
    milestones: [],
    shipmentReferences: [
      {
        id: 'sh-1',
        type: ShipmentReferenceTypeCode.Mawb,
        value: '201-90283064',
      },
    ],
    milestoneViews: {
      po: mockMilestones.milestonesSummary.ETAPlusPreviousETA,
    },
  }),
  createMockCargo({
    id: '203-4243532dfg',
    status: CargoStatus.InTransit,
    customerId: 'some-customer-id-2',
    createdAt: '2023-03-05T00:00:00Z',
    carrierShipmentId: '208-90123064',
    mode: Mode.Air,
    transportSummary: {
      transshipments: [],
      postPrimaryDischarge: [],
      prePrimaryLoad: [],
      postPrimaryDischargeLocations: [],
      transshipmentLocations: [],
      prePrimaryLoadLocations: [],
      primaryDischarge: {
        location: {
          name: 'London Heathrow',
          facility: {
            iataCode: 'LHR',
            type: FacilityType.Airport,
          },
          type: LocationType.Facility,
          id: '1234',
        },
        milestoneViews: {},
      },
      primaryLoad: {
        location: {
          name: 'Shenzen International Airport',
          facility: {
            iataCode: 'SZX',
            type: FacilityType.Airport,
          },
          type: LocationType.Facility,
          id: '6452',
        },
        milestoneViews: {},
      },
      haulagePairExists: false,
    },
    shipmentReferences: [
      {
        id: 'sh-1',
        type: ShipmentReferenceTypeCode.Mawb,
        value: '201-92279064',
      },
    ],
  }),
  createRoadMockCargo({
    milestoneViews: {
      po: mockMilestones.milestonesSummary.ETAPlusPreviousETA,
    },
  }),
];
