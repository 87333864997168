import { graphql } from '../../../../generated/gql';

export const savePosToCargoMutation = graphql(/* GraphQL */ `
  mutation savePosToCargo($cargoId: ID! = "", $externalBoardId: ID!, $carrierShipmentId: ID!, $pos: [PoInput!]! = []) {
    savePosToCargo(
      cargoId: $cargoId
      externalBoardId: $externalBoardId
      carrierShipmentId: $carrierShipmentId
      pos: $pos
    )
  }
`);
