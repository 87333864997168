export const CARGO_VIEW_TIMESTAMP_WITH_ZONE = 'TimestampWithZone';
interface CargoViewTimestampWithZone {
  type: typeof CARGO_VIEW_TIMESTAMP_WITH_ZONE;
  value: string;
  // there are two scenarios we need to handle
  // 1. a timezone value is passed e.g. "zone": "Asia/Shanghai"
  // 2. the timezone difference is included in the value e.g. "2023-02-28T08:00:00-05:00"
  zone: 'IncludeInValue' | string;
}

export const CARGO_VIEW_TIMESTAMP_WITHOUT_ZONE = 'TimestampWithoutZone';
interface CargoViewTimestampWithoutZone {
  type: typeof CARGO_VIEW_TIMESTAMP_WITHOUT_ZONE;
  value: string;
}

export const CARGO_VIEW_DATE = 'Date';
interface CargoViewDate {
  type: typeof CARGO_VIEW_DATE;
  value: string;
}

const CARGO_VIEW_DATE_EMPTY = 'Empty';
interface CargoViewDateEmpty {
  type: typeof CARGO_VIEW_DATE_EMPTY;
  value: '';
}

export type CargoViewDateTime =
  | CargoViewTimestampWithZone
  | CargoViewTimestampWithoutZone
  | CargoViewDate
  | CargoViewDateEmpty;
