import { format, parseISO, isValid, isSameDay } from 'date-fns';
import { toZonedTime, formatInTimeZone } from 'date-fns-tz';
import {
  CARGO_VIEW_DATE,
  CARGO_VIEW_TIMESTAMP_WITHOUT_ZONE,
  CARGO_VIEW_TIMESTAMP_WITH_ZONE,
  CargoViewDateTime,
} from '@beacon-types/cargo/date';

export const DATE_FORMAT = 'date';
export const DATE_WITHOUT_YEAR_FORMAT = 'dateWithoutYear';
export const DATE_TIME_FORMAT = 'dateTime';
export const DATE_TIME_WITHOUT_YEAR_FORMAT = 'dateTimeWithoutYear';
const DATE_SHORT_FORMAT = 'shortDate';
const DATE_TIME_ONLY = 'dateTimeHourOnly';

export type DateFormatType =
  | typeof DATE_FORMAT
  | typeof DATE_WITHOUT_YEAR_FORMAT
  | typeof DATE_TIME_FORMAT
  | typeof DATE_TIME_WITHOUT_YEAR_FORMAT
  | typeof DATE_SHORT_FORMAT
  | typeof DATE_TIME_ONLY;

const dateFormat = 'EEE, d MMM yyyy';
const dateWithoutYearFormat = 'EEE, d MMM';
const dateTimeFormat = 'EEE, d MMM yyyy HH:mm';
const dateTimeWithoutYearFormat = 'EEE, d MMM HH:mm';
const shortDateFormat = 'dd MMM yyyy';
const dateTimeOnlyFormat = 'HH:mm';

const INCLUDED_IN_VALUE = 'IncludeInValue';

const getFormat = (formatType?: DateFormatType, type?: string) => {
  if (
    formatType === DATE_TIME_FORMAT &&
    type &&
    [CARGO_VIEW_TIMESTAMP_WITH_ZONE, CARGO_VIEW_TIMESTAMP_WITHOUT_ZONE].includes(type)
  ) {
    return dateTimeFormat;
  }
  if (formatType === DATE_SHORT_FORMAT) {
    return shortDateFormat;
  }
  if (formatType === DATE_WITHOUT_YEAR_FORMAT) {
    return dateWithoutYearFormat;
  }
  if (formatType === DATE_TIME_WITHOUT_YEAR_FORMAT) {
    return dateTimeWithoutYearFormat;
  }
  if (formatType === DATE_TIME_ONLY) {
    return dateTimeOnlyFormat;
  }
  return dateFormat;
};

export const formatCargoDateTimeView = (cargoDateTime?: CargoViewDateTime, formatType?: DateFormatType): string => {
  const formatString = getFormat(formatType, cargoDateTime?.type);
  if (!cargoDateTime?.value) {
    return '';
  }

  const date = cargoDateTime.value;

  if (cargoDateTime.type === CARGO_VIEW_DATE) {
    return format(date, formatString);
  }

  if (cargoDateTime.type === CARGO_VIEW_TIMESTAMP_WITH_ZONE && cargoDateTime.zone) {
    if (cargoDateTime.zone === INCLUDED_IN_VALUE) {
      return format(date, formatString);
    }

    return formatInTimeZone(date, cargoDateTime.zone, formatString);
  }

  if (cargoDateTime.type === CARGO_VIEW_TIMESTAMP_WITHOUT_ZONE) {
    return formatInTimeZone(date, 'UTC', formatString);
  }

  return '';
};

const getTimeZone = (cargoDateTime: CargoViewDateTime) => {
  if (cargoDateTime.type === CARGO_VIEW_TIMESTAMP_WITH_ZONE && cargoDateTime.zone !== INCLUDED_IN_VALUE) {
    return cargoDateTime.zone;
  }
  return 'Europe/London';
};

export const formatCargoTateTimeWindowView = (
  startCargoDateTime?: CargoViewDateTime,
  endCargoDateTime?: CargoViewDateTime,
  formatType?: DateFormatType,
): string => {
  if (!startCargoDateTime?.value || !endCargoDateTime?.value) {
    if (startCargoDateTime?.value) {
      return `${formatCargoDateTimeView(startCargoDateTime, formatType)} -`;
    }
    if (endCargoDateTime?.value) {
      return `- ${formatCargoDateTimeView(endCargoDateTime, formatType)}`;
    }
    return '';
  }

  const startDate = parseISO(startCargoDateTime.value);
  const endDate = parseISO(endCargoDateTime.value);

  if (!isValid(startDate) || !isValid(endDate)) {
    return '';
  }

  const startAndEndAreInSameDay = isSameDay(
    toZonedTime(startDate, getTimeZone(startCargoDateTime)),
    toZonedTime(endDate, getTimeZone(endCargoDateTime)),
  );

  const isTimestampWindowOnSameDay =
    [CARGO_VIEW_TIMESTAMP_WITHOUT_ZONE, CARGO_VIEW_TIMESTAMP_WITH_ZONE].includes(endCargoDateTime.type) &&
    startAndEndAreInSameDay;
  const endCargoDateTimeFormat = isTimestampWindowOnSameDay ? DATE_TIME_ONLY : DATE_TIME_WITHOUT_YEAR_FORMAT;

  return `${formatCargoDateTimeView(startCargoDateTime, DATE_TIME_WITHOUT_YEAR_FORMAT)}${
    isTimestampWindowOnSameDay ? '-' : ' - '
  }${formatCargoDateTimeView(endCargoDateTime, endCargoDateTimeFormat)}`;
};
