import {
  Cargo,
  CargoStatus,
  Label,
  LocationType,
  Milestone,
  MilestoneType,
  Mode,
  PurchaseOrder,
  ShipmentTransportStage,
} from '@services/ShipmentDataGatewayService/generated/graphql';

export const ataMilestone: Milestone = {
  type: MilestoneType.ArrivedAtPdl,
  otherDateInfo: [],
  dateInfo: {
    eventDateTime: {
      timestamp: '2022-09-06T14:32:30.407Z',
    },
  },
  label: 'ATA',
  order: 0,
  shipmentTransportStage: ShipmentTransportStage.Unknown,
  isStandalonePreviousDateMilestone: false,
  hasBeenAchieved: false,
};

export const etaMilestone: Milestone = {
  type: MilestoneType.EtaAtPdl,
  otherDateInfo: [],
  dateInfo: {
    eventDateTime: {
      timestamp: '2022-09-06T14:32:30.407Z',
    },
  },
  label: 'ETA',
  order: 0,
  shipmentTransportStage: ShipmentTransportStage.Unknown,
  isStandalonePreviousDateMilestone: false,
  hasBeenAchieved: false,
};

const previousEtaMilestone: Milestone = {
  type: MilestoneType.PreviousEtaAtPdl,
  otherDateInfo: [],
  dateInfo: {
    eventDateTime: {
      timestamp: '2022-09-10T18:12:25.407Z',
    },
  },
  label: 'PREVOUS_ETA',
  order: 0,
  shipmentTransportStage: ShipmentTransportStage.Unknown,
  isStandalonePreviousDateMilestone: false,
  hasBeenAchieved: false,
};

export const gofMilestone: Milestone = {
  type: MilestoneType.GatedOutFull,
  otherDateInfo: [],
  dateInfo: {
    eventDateTime: {
      timestamp: '2022-09-12T14:32:30.407Z',
    },
  },
  label: 'GOF',
  order: 0,
  shipmentTransportStage: ShipmentTransportStage.Unknown,
  isStandalonePreviousDateMilestone: false,
  hasBeenAchieved: false,
};

export const dischargedAtDestMilestone: Milestone = {
  type: MilestoneType.DischargedAtPdl,
  otherDateInfo: [],
  dateInfo: {
    eventDateTime: {
      timestamp: '2022-09-11T14:32:30.407Z',
    },
  },
  label: 'DISCHARGED_AT_DEST',
  shipmentTransportStage: ShipmentTransportStage.Unknown,
  order: 0,
  isStandalonePreviousDateMilestone: false,
  hasBeenAchieved: false,
};

export const homepageCargo: Cargo = {
  id: '201-90283064',
  status: CargoStatus.InTransit,
  createdAt: new Date().toISOString(),
  slimPurchaseOrders: [],
  carrierShipmentId: '208-90123064',
  customerId: 'some-customer-id-1',
  documents: [],
  documentCount: 0,
  errors: [],
  container: {
    containerNumber: '207-90279064',
  },
  transportSummary: {
    transshipments: [],
    postPrimaryDischarge: [],
    prePrimaryLoad: [],
    postPrimaryDischargeLocations: [],
    prePrimaryLoadLocations: [],
    transshipmentLocations: [],
    primaryDischarge: {
      location: {
        name: 'Auckland',
        type: LocationType.Facility,
        id: '1234',
      },
      milestoneViews: {
        summary: [etaMilestone, previousEtaMilestone],
        full: [etaMilestone, previousEtaMilestone],
      },
    },
    primaryLoad: {
      location: {
        name: 'Felixstowe',
        type: LocationType.Facility,
        id: '6452',
      },
      milestoneViews: {},
    },
    currentVehicle: {
      id: 'some-vehicle-id',
      displayName: 'Some Vessel',
      vehicleEvents: [],
      mode: Mode.Ocean,
      identification: { imoNumber: '9365788' },
    },
    haulagePairExists: false,
  },
  transports: [],
  cargoEvents: [],
  customFields: [],
  milestones: [],
  shipmentReferences: [],
  labels: [
    { customerId: 'customer-1', name: 'Label 1', id: 'lbl-1' },
    { customerId: 'customer-1', name: 'Label 2', id: 'lbl-2' },
    { customerId: 'customer-1', name: 'Label 3', id: 'lbl-3' },
    { customerId: 'customer-1', name: 'Label 4', id: 'lbl-4' },
  ] as Label[],
};

export const homepagePO: PurchaseOrder = {
  id: 'bcn1.po.purchase-order.9e3506e3-c336-46ae-ad9b-d3e889f36835',
  customerId: 'customer-1',
  poNumber: 'PO 123',
  documents: [],
  documentCount: 0,
  createdOn: '2023-05-03T23:02:54Z',
  labels: [],
  isWatched: false,
  skus: [],
  version: 'v1',
  associatedCargos: [
    {
      id: 'bcn1.ti.cargo.326ea328-9cd4-49ae-b0f6-fce48a1a8588',
      customerId: 'customer-1',
      slimPurchaseOrders: [],
      labels: [],
      carrierShipmentId: 'carrierShipmentID',
      mode: Mode.Ocean,
      status: CargoStatus.LoadedAtOrigin,
      cargoEvents: [],
      createdAt: '',
      milestones: [],
      errors: [],
      shipmentReferences: [],
      transports: [],
      documents: [],
      customFields: [],
      documentCount: 0,
      container: {
        containerNumber: 'FFAU2537774',
      },
      isWatched: true,
      transportSummary: {
        postPrimaryDischarge: [],
        prePrimaryLoad: [],
        postPrimaryDischargeLocations: [],
        prePrimaryLoadLocations: [],
        transshipmentLocations: [],
        primaryLoad: {
          location: {
            id: '',
            name: 'Long Beach, CA',
            type: LocationType.Facility,
          },
          milestoneViews: {},
        },
        primaryDischarge: {
          location: {
            id: '',
            name: 'Surabaya',
            type: LocationType.Facility,
          },
          milestoneViews: {
            full: [
              {
                type: MilestoneType.EtaAtPdl,
                label: 'ETA',
                otherDateInfo: [],
                dateInfo: {
                  eventDateTime: {
                    date: null,
                    timestamp: '2023-06-01T23:00:00Z',
                  },
                },
                isStandalonePreviousDateMilestone: false,
                shipmentTransportStage: ShipmentTransportStage.Unknown,
                order: 1,
                hasBeenAchieved: false,
              },
            ],
          },
        },
        transshipments: [],
        haulagePairExists: false,
      },
      milestoneViews: {
        po: [],
      },
    },
  ],
};
