import { theme } from '@beacon-devops/components';
import '@features/app/base.css';
import '@features/app/cord-custom.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { TooltipProvider } from '@components/common/RCTooltip';
import { Auth0Provider } from '@features/auth';
import { FeatureFlagProvider } from '@features/featureFlags/components/FeatureFlagProvider';
import { sessionStorageCache } from '@services/AuthService';
import { isLocal } from '@utils/env';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function Root() {
  return (
    <Auth0Provider
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      cache={sessionStorageCache}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <FeatureFlagProvider>
          <ThemeProvider theme={theme}>
            <TooltipProvider>
              <Outlet />
            </TooltipProvider>
          </ThemeProvider>
        </FeatureFlagProvider>
        {isLocal && <ReactQueryDevtools initialIsOpen={false} position="bottom" />}
      </QueryClientProvider>
    </Auth0Provider>
  );
}
