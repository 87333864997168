import { SearchPurchaseOrdersByExternalBoardIdQuery } from '@services/LiveBoardsService/generated/graphql';
import { graphql } from '../../../generated/gql';

export const searchPurchaseOrdersByExternalBoardIdQuery = graphql(/* GraphQL */ `
  query searchPurchaseOrdersByExternalBoardId(
    $page: Int! = 0
    $size: Int! = 20
    $sort: [SearchSortCriteria!]
    $externalBoardId: String!
  ) {
    searchPurchaseOrdersByExternalBoardId(page: $page, size: $size, sort: $sort, externalBoardId: $externalBoardId) {
      page
      pageSize
      totalPages
      totalRecords
      pos {
        id
        customerSupplierId
        poNumber
        supplierName
        forwarderName
        cargoReadyDate

        isWatched
        labels {
          id
          name
        }
        documents {
          id
          associatedId
          sizeBytes
          type
          name
          createdAt
          createdBy
          createdByUserId
          sizeBytes
          boardId
        }
        associatedCargos {
          createdAt
          demurrageInfo {
            daysOnQuayPoD
            daysAtTsPort
            daysOffQuayDest
          }
          id
          errors {
            type
          }
          milestoneViews {
            po {
              type
              dateInfo {
                eventDateTime {
                  date
                  timestamp
                  zone
                }
                timeframeStart {
                  date
                  timestamp
                  zone
                }
                timeframeEnd {
                  date
                  timestamp
                  zone
                }
                lastFetched
                sourceName
                order
              }
              label
              order
              isStandalonePreviousDateMilestone
              type
            }
          }
          mode
          status
          transportSummary {
            legs
            currentLeg
            haulagePairExists
            currentVehicle {
              identification {
                licensePlate
              }
            }
            prePrimaryLoad {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
            origin {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
            primaryLoad {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
            transshipments {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
            primaryDischarge {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
            postPrimaryDischarge {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
            destination {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
            warehouse {
              location {
                id
                name
                facility {
                  iataCode
                }
              }
              milestoneViews {
                full {
                  type
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  label
                  order
                }
              }
            }
          }
          shipmentInfo {
            currentSplit
            totalSplits
          }
          shipmentReferences {
            type
            value
          }
          container {
            containerNumber
          }
        }
        version
      }
    }
  }
`);

export type BoardOrders = SearchPurchaseOrdersByExternalBoardIdQuery['searchPurchaseOrdersByExternalBoardId'];
