import { Dispatch } from 'react';
import { NumOfTableRowsPerPage } from '@beacon-devops/components';
import _isNumber from 'lodash.isnumber';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { ContainerOrPurchaseOrderView } from '@components/tracking/types';
import { useCurrentUserIfPresentFromContextAsync } from '@features/user/currentUser/context';
import { Mode } from '@services/ShipmentDataGatewayService/generated/graphql';
import { filterMappingOverride } from './filterMappingOverride';
import { getLegacyFilters } from './legacyFilters';

export const queryStringToString = (value: string | string[] | undefined) => {
  if (Array.isArray(value)) return value[0];
  return value;
};

const queryStringToArray = (value: string | undefined) => {
  try {
    return value && JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const parseSafely = (num: string | string[] | undefined, fallback: number) => {
  const n = parseInt(queryStringToString(num) ?? fallback.toString(), 10);

  return Math.max(Number.isNaN(n) ? fallback : n, 1);
};

export interface ContainerSearchParameters {
  mode?: `${Mode}`;
  page: number;
  pageSize: number;
  query: string;
  isDelayedOrEarly: boolean;
  sort: string;
  departureStartDate?: string;
  departureEndDate?: string;
  arrivalStartDate?: string;
  arrivalEndDate?: string;
  filters?: string[];
  status?: string;
}

export interface TrackingSearchParameters {
  mode?: `${Mode}`;
  page: number;
  pageSize: NumOfTableRowsPerPage;
  query: string;
  sort: string;
  relativeDate?: string;
  startDate?: string;
  endDate?: string;
  filters?: string[];
  portOfDischarge?: string[];
  portOfLoading?: string[];
  origin?: string[];
  destination?: string[];
  warehouses?: string[];
  carriers?: string[];
  vessels?: string[];
  selectedView?: string;
  milestone?: string;
  cargoReadyDate?: string;
  containerOrPurchaseOrderView?: string;
  numberOfItemsFound?: number;
  routingDirect?: boolean;
  routingIndirect?: boolean;
  etaChangedFilter?: boolean;
  watchlistFilter?: boolean;
  suppliers?: string[];
  transhipmentPorts?: string[];
  demurrageFilter: boolean;
  demurrageValue: number;
  daysOffQuayFilter: boolean;
  daysOffQuayValue: number;
  labels?: string[];
  isDaysAtTsPortOn?: boolean;
  daysAtTsPort?: number;
  flightNumber?: string[];
}

type URLSearchParams = {
  mode: `${Mode}`;
  page: string;
  pageSize: string;
  query: string;
  status: string;
  relativeDate?: string;
  startDate?: string;
  endDate?: string;
  filters?: string;
  portOfDischarge?: string;
  portOfLoading?: string;
  origin?: string;
  destination?: string;
  milestone?: string;
  cargoReadyDate?: string;
  flightNumber?: string;
  containerOrPurchaseOrderView?: string;
  warehouses?: string;
  carriers?: string;
  labels: string;
  vessels?: string;
  transhipmentPorts?: string;
  sort?: string;
  suppliers?: string;
  numberOfItemsFound?: string;
  routingDirect?: string;
  routingIndirect?: string;
  etaChangedFilter?: string;
  watchlistFilter?: string;
  demurrageFilter?: string;
  demurrageValue?: string;
  daysOffQuayFilter?: string;
  daysOffQuayValue?: string;
  isDaysAtTsPortOn?: string;
  daysAtTsPort?: string;
};

export const useQueryStringTrackingSearch = (): [TrackingSearchParameters, Dispatch<TrackingSearchParameters>] => {
  const { permissions } = useCurrentUserIfPresentFromContextAsync();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDemurrageUser = permissions?.canReadDaysOnQuay || false;
  const isDaysOffQuayUser = permissions?.canReadDaysOffQuay || false;

  const searchParamsObj = {
    ...{
      page: '1',
      pageSize: '20',
      query: '',
      sort: 'arrivalsDesc',
      status: 'active',
    },
    ...Object.fromEntries([...searchParams]),
    filters: filterMappingOverride(searchParams.get('filters') || undefined),
  } as URLSearchParams;

  const legacyFilters = getLegacyFilters(location.search);

  const {
    mode,
    page,
    pageSize,
    query,
    relativeDate,
    startDate,
    endDate,
    filters,
    portOfDischarge,
    portOfLoading,
    origin,
    destination,
    milestone,
    cargoReadyDate,
    containerOrPurchaseOrderView,
    warehouses,
    carriers,
    labels,
    vessels,
    sort,
    suppliers,
    transhipmentPorts,
    numberOfItemsFound,
    routingDirect,
    routingIndirect,
    etaChangedFilter,
    watchlistFilter,
    demurrageFilter,
    demurrageValue,
    daysAtTsPort,
    isDaysAtTsPortOn,
    flightNumber,
    daysOffQuayFilter,
    daysOffQuayValue,
  } = { ...searchParamsObj, ...legacyFilters };

  const setState: Dispatch<TrackingSearchParameters> = (value) => {
    const data: Record<string, string | string[]> = {
      page: value.page.toString(),
      pageSize: value.pageSize.toString(),
      query: value.query,
    };

    if (value.mode) {
      data.mode = value.mode;
    }

    if (value.filters && value.filters.length > 0) {
      data.filters = JSON.stringify(value.filters);
    }

    if (value.origin && value.origin.length > 0) {
      data.origin = JSON.stringify(value.origin);
    }

    if (value.destination && value.destination.length > 0) {
      data.destination = JSON.stringify(value.destination);
    }

    if (value.portOfLoading && value.portOfLoading.length > 0) {
      data.portOfLoading = JSON.stringify(value.portOfLoading);
    }

    if (value.portOfDischarge && value.portOfDischarge.length > 0) {
      data.portOfDischarge = JSON.stringify(value.portOfDischarge);
    }

    if (value.flightNumber && value.flightNumber.length > 0) {
      data.flightNumber = JSON.stringify(value.flightNumber);
    }

    if (value.warehouses && value.warehouses.length > 0) {
      data.warehouses = JSON.stringify(value.warehouses);
    }

    if (value.carriers && value.carriers.length > 0) {
      data.carriers = JSON.stringify(value.carriers);
    }

    if (value.labels && value.labels.length > 0) {
      data.labels = JSON.stringify(value.labels);
    }

    if (value.vessels && value.vessels.length > 0) {
      data.vessels = JSON.stringify(value.vessels);
    }

    if (value.suppliers && value.suppliers.length > 0) {
      data.suppliers = JSON.stringify(value.suppliers);
    }

    if (value.transhipmentPorts && value.transhipmentPorts.length > 0) {
      data.transhipmentPorts = JSON.stringify(value.transhipmentPorts);
    }

    if (value.milestone) {
      data.milestone = value.milestone;
    }

    if (value.cargoReadyDate) {
      data.cargoReadyDate = value.cargoReadyDate;
    }

    if (value.sort) {
      data.sort = value.sort;
    }

    if (value.relativeDate) {
      data.relativeDate = value.relativeDate;
    }

    if (value.startDate) {
      data.startDate = value.startDate;
    }

    if (value.endDate) {
      data.endDate = value.endDate;
    }

    if (value.containerOrPurchaseOrderView) {
      data.containerOrPurchaseOrderView = value.containerOrPurchaseOrderView;
    }

    if (value.numberOfItemsFound) {
      data.numberOfItemsFound = value.numberOfItemsFound.toString();
    }

    if (value.routingDirect) {
      data.routingDirect = 'true';
    }

    if (value.routingIndirect) {
      data.routingIndirect = 'true';
    }

    if (value.etaChangedFilter) {
      data.etaChangedFilter = 'true';
    }

    if (value.watchlistFilter) {
      data.watchlistFilter = 'true';
    }

    if (value.demurrageFilter) {
      data.demurrageFilter = 'true';
      data.demurrageValue = value.demurrageValue.toString();
    }
    if (value.demurrageValue) {
      data.demurrageValue = value.demurrageValue.toString();
    }
    if (isDemurrageUser === false) {
      data.demurrageFilter = '';
    }

    if (value.daysOffQuayFilter && isDaysOffQuayUser) {
      data.daysOffQuayFilter = 'true';
      data.daysOffQuayValue = value.daysOffQuayValue.toString();
    }

    if (value.daysOffQuayValue) {
      data.daysOffQuayValue = value.daysOffQuayValue.toString();
    }

    if (value.isDaysAtTsPortOn) {
      data.isDaysAtTsPortOn = 'true';
    }

    if (_isNumber(value.daysAtTsPort)) {
      data.daysAtTsPort = value.daysAtTsPort.toString();
    }

    setSearchParams(createSearchParams(data), { replace: true });
  };

  return [
    {
      mode: (queryStringToString(mode) as Mode) ?? undefined,
      page: parseSafely(page, 1),
      pageSize: parseSafely(pageSize, 20) as NumOfTableRowsPerPage,
      query: queryStringToString(query) ?? '',
      relativeDate: queryStringToString(relativeDate),
      endDate: queryStringToString(endDate),
      startDate: queryStringToString(startDate),
      filters: queryStringToArray(filters),
      portOfDischarge: queryStringToArray(portOfDischarge),
      portOfLoading: queryStringToArray(portOfLoading),
      origin: queryStringToArray(origin),
      destination: queryStringToArray(destination),
      vessels: queryStringToArray(vessels),
      warehouses: queryStringToArray(warehouses),
      carriers: queryStringToArray(carriers),
      labels: queryStringToArray(labels),
      suppliers: queryStringToArray(suppliers),
      transhipmentPorts: queryStringToArray(transhipmentPorts),
      milestone: queryStringToString(milestone),
      cargoReadyDate: queryStringToString(cargoReadyDate),
      containerOrPurchaseOrderView:
        queryStringToString(containerOrPurchaseOrderView) ??
        queryStringToString(ContainerOrPurchaseOrderView.CONTAINERS),
      sort: queryStringToString(sort) ?? 'arrivalsDesc',
      numberOfItemsFound: parseInt((numberOfItemsFound && numberOfItemsFound.toString()) || '0', 0),
      routingDirect: routingDirect === 'true',
      routingIndirect: routingIndirect === 'true',
      etaChangedFilter: etaChangedFilter === 'true',
      watchlistFilter: watchlistFilter === 'true',
      demurrageFilter: demurrageFilter === 'true',
      demurrageValue: parseInt((demurrageValue && demurrageValue.toString()) || '0', 0),
      daysOffQuayFilter: daysOffQuayFilter === 'true',
      daysOffQuayValue: parseInt((daysOffQuayValue && daysOffQuayValue.toString()) || '0', 0),
      isDaysAtTsPortOn: isDaysAtTsPortOn === 'true',
      daysAtTsPort: daysAtTsPort?.length ? parseInt(daysAtTsPort) : undefined,
      flightNumber: queryStringToArray(flightNumber),
    },
    setState,
  ];
};
