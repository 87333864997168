import { getGraphqlClient } from '@features/api';
import { graphql } from '../../../generated/gql';
import { VehicleEvent } from '../../../generated/graphql';
import { endpoint } from '../../endpoint';

const getVehicleEventsByImoNumberQuery = graphql(/* GraphQL */ `
  query GetVehicleEventsByImoNumber($imoNumbers: [String!]!) {
    vehicleEventsByImoNumber(imoNumbers: $imoNumbers) {
      id
      type
      subType
      source {
        sourceType
        identifier
        displayName
      }
      eventDateTime {
        date
        timestamp
      }
      vehicle {
        id
        identification {
          imoNumber
          mmsiNumber
          tailNumber
          licensePlate
        }
      }
      trackingInfo {
        geoLocation {
          lat
          lon
        }
        headingDegrees
        speedKph
      }
    }
  }
`);

export const getVehicleLocations = async (vehicleIds: string[]): Promise<Array<VehicleEvent>> => {
  const client = await getGraphqlClient(endpoint);
  return client
    .request(getVehicleEventsByImoNumberQuery, { imoNumbers: vehicleIds })
    .then((data) => data.vehicleEventsByImoNumber as Array<VehicleEvent>);
};
