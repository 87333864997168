import {
  CargoEventType,
  CargoStatus,
  EventClassifier,
  EventType,
  LocationType,
  Mode,
  PurchaseOrder,
  ShipmentTransportStage,
  SourceType,
} from '@services/ShipmentDataGatewayService/generated/graphql';
import { createMockCargo } from './cargoList';

/** easily create mocked POs if needed by any tests, only specify props needed */
export const createMockPO = (overrides?: Partial<PurchaseOrder>): PurchaseOrder => ({
  poNumber: 'MY-PO-1',
  id: 'my-unique-po-1',
  customerSupplierId: '',
  customerId: 'my-unique-customer-1',
  version: '1',
  documents: [],
  documentCount: 0,
  labels: [],
  createdOn: '2023-05-03T23:02:54Z',
  isWatched: false,
  skus: [],
  associatedCargos: [createMockCargo()],
  ...overrides,
});

export const CargoPOMock: PurchaseOrder = {
  poNumber: 'MY-PO-1',
  id: 'my-unique-po-1',
  customerSupplierId: '',
  customerId: 'my-unique-customer-1',
  createdOn: '2023-05-03T23:02:54Z',
  labels: [],
  isWatched: false,
  skus: [],
  version: 'v1',
  documents: [],
  documentCount: 0,
  associatedCargos: [
    {
      id: '201-90283061',
      labels: [],
      slimPurchaseOrders: [],
      status: CargoStatus.InTransit,
      createdAt: new Date().toISOString(),
      carrierShipmentId: '208-90123064',
      documents: [],
      documentCount: 0,
      customerId: 'some-customer-id-1',
      container: {
        containerNumber: '207-90279064',
      },
      errors: [],
      mode: Mode.Ocean,
      transportSummary: {
        postPrimaryDischarge: [],
        prePrimaryLoad: [],
        transshipments: [],
        postPrimaryDischargeLocations: [],
        prePrimaryLoadLocations: [],
        transshipmentLocations: [],
        primaryDischarge: {
          location: {
            name: 'Felixstowe',
            type: LocationType.Facility,
            id: '1234',
          },
          milestoneViews: {},
        },
        primaryLoad: {
          location: {
            name: 'Auckland',
            type: LocationType.Facility,
            id: '6452',
          },
          milestoneViews: {},
        },
        haulagePairExists: false,
      },
      transports: [
        {
          id: 'transport.123',
          mode: Mode.Ocean,
          dischargeTransportStage: ShipmentTransportStage.Unknown,
          loadTransportStage: ShipmentTransportStage.Unknown,
        },
      ],
      cargoEvents: [
        {
          classifier: EventClassifier.Planned,
          source: {
            sourceType: SourceType.ThirdPartyApi,
            confidence: 0.8,
            displayName: 'Some API',
            identifier: 'some',
          },
          eventDateTime: {
            date: new Date().toISOString(),
          },
          subType: CargoEventType.CustomsClear,
          type: EventType.Cargo,
        },
      ],
      milestones: [],
      shipmentReferences: [],
      customFields: [],
    },
  ],
};
