import { getGraphqlClient } from '@features/api';
import { graphql } from '../../../generated/gql';
import { GqlMacro, PagedSearchResponse, SearchCriteria, SearchSortCriteria } from '../../../generated/graphql';
import { endpoint } from '../../endpoint';

export const searchCargosForTableQuery = graphql(/* GraphQL */ `
  query searchCargosTableView(
    $page: Int! = 0
    $size: Int! = 20
    $criteria: [SearchCriteria!]
    $macros: [GqlMacro!]
    $sort: [SearchSortCriteria!]
    $isDaysOffQuayUser: Boolean!
  ) {
    searchCargosTableView(page: $page, size: $size, criteria: $criteria, macros: $macros, sort: $sort) {
      page
      pageSize
      totalPages
      totalRecords
      cargos {
        id
        status
        carrierShipmentId
        mode
        createdAt
        createdByName
        customFields {
          key
          value
        }
        container {
          containerNumber
        }
        carrier {
          displayName
          nmftaCode
        }
        slimPurchaseOrders {
          id
          poNumber
          supplierName
        }
        shipmentReferences {
          type
          partyName
        }
        demurrageInfo {
          daysOnQuayPoD
          daysOffQuayDest @include(if: $isDaysOffQuayUser)
        }
        transportSummary {
          primaryLoad {
            location {
              name
            }
          }
          primaryDischarge {
            location {
              name
            }
          }
          currentVehicle {
            displayName
            identification {
              imoNumber
            }
          }
          warehouse {
            location {
              name
              id
            }
          }
          transshipments {
            location {
              name
            }
          }
        }
        shipmentReferences {
          id
          name
          type
          value
        }
        timeline {
          legs {
            location {
              name
            }
            locationLabel
            onwardTransportInfo {
              mode
              vehicle {
                displayName
              }
              isCurrent
            }
            isFinalDestination
            milestones {
              order
              type
              label
              hasBeenAchieved
              dateInfo {
                eventDateTime {
                  date
                  timestamp
                  zone
                }
                sourceName
                lastFetched
              }
            }
          }
        }
      }
    }
  }
`);

interface SearchCargosForTableParams {
  page: number;
  size: number;
  sort?: SearchSortCriteria[];
  criteria: SearchCriteria[] | [];
  macros: GqlMacro[] | [];
  isDaysOffQuayUser: boolean;
}

export const searchCargosForTable = async ({
  page,
  size,
  sort,
  criteria,
  macros,
  isDaysOffQuayUser = false,
}: SearchCargosForTableParams): Promise<PagedSearchResponse> => {
  const client = await getGraphqlClient(endpoint);
  const data = await client.request(searchCargosForTableQuery, {
    page: page > 0 ? page - 1 : 0,
    size,
    sort,
    criteria,
    macros,
    isDaysOffQuayUser,
  });

  return data.searchCargosTableView as PagedSearchResponse;
};
