import { useEffect, useRef, useState } from 'react';
import { Box, Button, Text, TextButton } from '@beacon-devops/components';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FlexContainer } from '@components/common/FlexContainerWithGap/styled';
import { RequiredDropdown, RequiredTextInput } from '@components/common/RequiredInputs';
import { useIsPhone } from '@components/responsive';
import { BoardMode } from '@features/boards';
import { HubSpotSignupFormModal } from '@features/freeTracking';
import {
  featureAnalyticsCarrierSelectAuto,
  featureAnalyticsCarrierSelectManual,
  featureAnalyticsTrackMoreCarriersClick,
} from '@features/freeTracking/analytics/freeTrackingPageAnalytics';
import { AddRemainingShipments } from '@features/freeTracking/components/addRemainingShipments/AddRemainingShipments';
import { StartTrackingFormValues } from '@features/freeTracking/components/startTrackingForm/types';
import { ModeSpecificText } from '@features/freeTracking/constants';
import { Mode } from '@services/ShipmentDataGatewayService';
import { getCarrierCodeForContainerNumber } from '@utils/tracking';

interface Props {
  carrierList: {
    label: string;
    value: string;
  }[];
  handleStartTracking: (values: StartTrackingFormValues) => void;
  errors?: {
    message: string;
    shipmentNumber: string;
  }[];
  boardIdForHubspotSignup: string;
  mode: BoardMode;
  updateBoardSuccessful: boolean;
  remainingShipments: number;
  handleCloseModal: () => void;
}

export function AddExtraShipmentsForm({
  carrierList,
  handleStartTracking,
  errors,
  boardIdForHubspotSignup,
  mode = Mode.Ocean,
  updateBoardSuccessful,
  remainingShipments,
  handleCloseModal,
}: Props) {
  const defaultShipmentValue = {
    shipmentIdentifier: '',
    carrierCode: '',
  };
  // State to disable carrier selection when the carrier is auto selected
  const [carrierSelectionDisabled, setCarrierSelectionDisabled] = useState(false);
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { isDirty, isValid },
    reset,
  } = useForm<StartTrackingFormValues>({
    defaultValues: {
      shipments: [defaultShipmentValue],
    },
  });
  const { fields, append } = useFieldArray({
    name: 'shipments',
    control,
  });

  const [isSigninUp, setIsSigninUp] = useState(false);

  // Ref for shipment list to scroll down the list when a new shipment is added
  const shipmentListRef = useRef<HTMLDivElement>(null);

  /**
   * Effect to set errors on form fields when errors are received from the API
   */
  useEffect(() => {
    if (errors?.length) {
      const formValues = getValues();

      errors.forEach(({ shipmentNumber, message }) => {
        const index = formValues.shipments.findIndex((field) => field.shipmentIdentifier === shipmentNumber);
        if (index === -1) {
          formValues.shipments.forEach((_, i) => {
            setError(`shipments.${i}.shipmentIdentifier`, {
              type: 'custom',
              message: 'Container cannot be tracked, please try again later',
            });
          });
        } else {
          setError(`shipments.${index}.shipmentIdentifier`, {
            type: 'custom',
            message,
          });
        }
      });
    }
  }, [errors, getValues, setError, clearErrors]);

  /**
   * Effect to reset the form when a shipment is added to the board successfully
   */
  useEffect(() => {
    if (updateBoardSuccessful) {
      reset();
    }
  }, [updateBoardSuccessful]);

  const isOceanMode = mode === Mode.Ocean;

  /**
   * Form submit function to handle the form submission, when the board exists
   * for the mode, we show the signup modal instead of creating a new board
   * making sure only one board is created per mode
   */
  function onFormSubmit(values: StartTrackingFormValues) {
    if (remainingShipments === 0) {
      setIsSigninUp(true);
    } else {
      handleStartTracking(values);
      if (isOceanMode && !carrierSelectionDisabled) {
        values.shipments.forEach((shipment) => {
          const carrier = carrierList.find((carrier) => carrier.value === values.shipments[0].carrierCode);
          if (carrier) {
            featureAnalyticsCarrierSelectManual(mode, carrier.label, carrier.value, true);
          }
        });
      }
    }
  }

  /**
   * The function to set the carrier code for the shipment number
   * When the carrier code is set for the shipment number, we disable the carrier selection
   */
  function setCarrierCodeForShipmentNumber(shipmentNumberText: string, index: number) {
    if (isOceanMode) {
      const carrierCodeForContainer = getCarrierCodeForContainerNumber(shipmentNumberText);
      const carrier = carrierList.find((carrier) => carrier.value === carrierCodeForContainer);

      if (carrier) {
        setValue(`shipments.${index}.carrierCode`, carrier.value);
        setCarrierSelectionDisabled(true);
        featureAnalyticsCarrierSelectAuto(mode, carrier.label, carrier.value, true);
      } else {
        setCarrierSelectionDisabled(false);
      }
    }
  }

  function onShipmentNumberChange(shipmentNumberText: string, index: number) {
    setCarrierCodeForShipmentNumber(shipmentNumberText, index);
  }

  function addMoreShipments() {
    append(defaultShipmentValue);
    /**
     * Scroll the shipment list to bottom, when a new item is added
     * so that the user can see the new item added
     */
    setTimeout(() => {
      shipmentListRef.current?.scrollTo({
        top: shipmentListRef.current?.clientHeight,
      });
    }, 100);
  }

  const isPhone = useIsPhone();
  const carrierNamePlaceHolder = isPhone ? 'Carrier name' : 'Select a carrier';

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FlexContainer width="100%" maxHeight="360px" overflowY="auto" ref={shipmentListRef}>
          <FlexContainer flex={2} flexDirection="column">
            <FlexContainer gap="24px" flex={1}>
              <Box flex={1}>
                <Text fontSize={2}>{ModeSpecificText[mode].cargoLabel}</Text>
              </Box>
              {mode === Mode.Ocean && (
                <Box flex={1}>
                  <Text fontSize={2}>Carrier</Text>
                </Box>
              )}
            </FlexContainer>
            {fields.map((field, index) => (
              <FlexContainer key={field.id} gap={isPhone ? '12px' : '24px'} mb={3}>
                <Box flex={1}>
                  <Controller
                    name={`shipments.${index}.shipmentIdentifier`}
                    control={control}
                    rules={{ required: index === 0 && remainingShipments > 0 }}
                    render={RequiredTextInput(
                      '',
                      ModeSpecificText[mode].cargoInputPlaceHolder,
                      'Shipment number',
                      (value) => onShipmentNumberChange(value, index),
                    )}
                  />
                </Box>
                {mode === Mode.Ocean && (
                  <Box flex={1}>
                    <Controller
                      name={`shipments.${index}.carrierCode`}
                      control={control}
                      rules={{ required: index === 0 && remainingShipments > 0 }}
                      render={RequiredDropdown({
                        placeholder: carrierNamePlaceHolder,
                        options: carrierList,
                        isSingleSelectBehaviour: true,
                        showSearch: true,
                        shouldClearOnClickOutside: false,
                        showChevronOnInputSearch: true,
                        errorFieldNameLabel: 'Carrier',
                        isDisabled: carrierSelectionDisabled,
                        footer: {
                          element: (
                            <Box p={4}>
                              <TextButton
                                type="button"
                                onClick={() => {
                                  setIsSigninUp(true);
                                  featureAnalyticsTrackMoreCarriersClick(mode, true);
                                }}
                              >
                                Track 165+ more carriers
                              </TextButton>
                            </Box>
                          ),
                        },
                      })}
                    />
                  </Box>
                )}
              </FlexContainer>
            ))}
            <AddRemainingShipments
              mode={mode}
              isReturningUser
              hasRemainingShipments={fields.length >= remainingShipments}
              remainingShipments={remainingShipments}
              onAddShipment={addMoreShipments}
              addShipmentCtaLabel="+ Add more"
              showRemainingShipments={false}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer mt="32px" gap="24px" flex={2} width="100%" justifyContent="space-between">
          <Box flex={1}>
            <Button
              variant="outlined"
              color="secondary"
              width="100%"
              size="large"
              style={{ height: `48px` }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Box>
          <Box flex={1}>
            <Button
              width="100%"
              size="large"
              style={{ height: `48px` }}
              disabled={!isDirty || !isValid || remainingShipments === undefined || remainingShipments <= 0}
            >
              Add
            </Button>
          </Box>
        </FlexContainer>
      </form>

      {boardIdForHubspotSignup && (
        <>
          <HubSpotSignupFormModal
            isOpen={isSigninUp}
            close={() => setIsSigninUp(false)}
            title="Track 20 shipments with 165+ carriers for free"
            boardId={boardIdForHubspotSignup}
            hasBackdrop
          />
        </>
      )}
    </>
  );
}
