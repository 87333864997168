import { Text } from '@beacon-devops/components';
import { styled } from 'styled-components';

export const ExpanderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -10px;
`;

export const ValueWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 16px;
  row-gap: 12px;
  background-color: #f5f7fa;
  padding: 12px 8px;
  margin-top: 12px;
  border-radius: 8px;
`;

export const MoreText = styled(Text)`
  font-size: 12px;
  margin-left: 2px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondaryShades[2]}};
`;

export const PopoverValueWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
`;
