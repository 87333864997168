import { styled } from 'styled-components';

export const CardExpanderWrapper = styled.button`
  width: 44px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryShades[6]};
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover svg {
    color: ${({ theme }) => theme.colors.secondaryShades[2]};
  }
`;
