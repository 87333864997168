import { getGraphqlClient } from '@features/api';
import { graphql } from '../../generated/gql';
import { PagedPurchaseOrders } from '../../generated/graphql';
import { endpoint } from '../endpoint';

const querySearchDrawerPurchaseOrders = graphql(/* GraphQL */ `
  query searchDrawerPurchaseOrders($customerId: ID!) {
    searchPurchaseOrdersByCustomerId(
      page: 0
      size: 10000
      customerId: $customerId
      criteria: []
      sort: []
      macros: []
    ) {
      totalRecords
      pos {
        id
        poNumber
        supplierName
        customerSupplierId
      }
    }
  }
`);

export const searchDrawerPurchaseOrders = async ({
  customerId,
}: {
  customerId: string;
}): Promise<PagedPurchaseOrders> => {
  const client = await getGraphqlClient(endpoint);
  const data = await client.request(querySearchDrawerPurchaseOrders, {
    customerId,
  });

  return data.searchPurchaseOrdersByCustomerId as PagedPurchaseOrders;
};
