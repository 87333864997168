import { graphql } from '../../../../generated/gql';
import { CustomerSuppliersQuery } from '../../../../generated/graphql';

export const customerSuppliersQuery = graphql(/* GraphQL */ `
  query customerSuppliers($externalBoardId: String!) {
    customerSuppliers(externalBoardId: $externalBoardId) {
      customerId
      supplierId
      legalName
    }
  }
`);

export type CustomerSupplierFromQuery = CustomerSuppliersQuery['customerSuppliers'];
