import { Dropdown, DropdownProps, Text } from '@beacon-devops/components';
import { ControllerRenderProps, ControllerFieldState, FieldValues, FieldPath } from 'react-hook-form';

interface RequiredInputDropdownProps extends Partial<DropdownProps> {
  customOutsideLabel?: string;
  isDisabled?: boolean;
  /**
   * @description In case the label is not present this field helps to show the error message
   * with the field name
   */
  errorFieldNameLabel?: string;
  onOptionSelect?: (value: OptionValue | OptionValue[], label: string | string[]) => void;
  setSearchTermAsSelectedOption?: boolean;
}

export const RequiredDropdown =
  ({
    customOutsideLabel,
    options,
    label,
    placeholder,
    showSearch,
    variant,
    isSingleSelectBehaviour,
    showChevronOnInputSearch,
    showAddNewOptionText,
    shouldClearOnClickOutside = true,
    newOptionText,
    onOptionSelect,
    onClickNewOption,
    isDisabled,
    errorFieldNameLabel,
    footer,
    isClearable,
    includeSearchAsOption,
    setSearchTermAsSelectedOption,
  }: RequiredInputDropdownProps) =>
  <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    field,
    fieldState,
  }: {
    field: ControllerRenderProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
  }) => {
    return (
      <>
        {customOutsideLabel && (
          <Text fontSize={2} mb="6px">
            {customOutsideLabel}
          </Text>
        )}

        <Dropdown
          id={field.name}
          onChange={(value: OptionValue | OptionValue[], label: string | string[]) => {
            if (onOptionSelect) {
              onOptionSelect(value, label);
            }
            field.onChange(value, label);
          }}
          options={options || []}
          withBorder
          placeholder={placeholder}
          value={field.value}
          label={label}
          showSearch={showSearch}
          isClearable={isClearable}
          variant={variant || 'default'}
          shouldClearOnClickOutside={shouldClearOnClickOutside}
          isSingleSelectBehaviour={isSingleSelectBehaviour}
          setSearchTermAsSelectedOption={setSearchTermAsSelectedOption}
          showChevronOnInputSearch={showChevronOnInputSearch}
          showAddNewOptionText={showAddNewOptionText}
          newOptionText={newOptionText}
          onClickNewOption={onClickNewOption}
          includeSearchAsOption={includeSearchAsOption}
          hasError={fieldState.invalid}
          disabled={isDisabled}
          footer={footer}
        />

        {fieldState.invalid && fieldState.error?.type !== 'no-message' && (
          <Text color="error" mt={2} fontSize={1}>
            {fieldState.error?.message || `${customOutsideLabel || label || errorFieldNameLabel} is required`}
          </Text>
        )}
      </>
    );
  };
