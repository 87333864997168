import Hotjar from '@hotjar/browser';
import { apiObject } from 'rudder-sdk-js';
import { analytics } from '@features/thirdPartyTools';
import { rudderanalytics } from '@services/clickstream';

const beaconIdRegex = new RegExp(/\/[a-z]+1.[a-z-]*.[a-z-]*.[a-z-0-9]*/, 'gi');

const generatePageNameFromCurrentLocation = (): string => {
  return window.location.pathname.replace(beaconIdRegex, '').substring(1).replace(/\//gi, '-');
};

interface TrackEventProps {
  element: string;
  action: string;
  pathOverride?: string;
  data?: apiObject;
  alsoSendTo?: {
    intercom?:
      | boolean
      | {
          name?: string;
          data?: apiObject;
        };
  };
}

/**
 * Triggers an analytics event with its name having a prefix based on the current location.
 *
 * This function was created to normalise how we name events. For cases in which more control over the event name is
 * warranted
 *
 * @returns void
 */
export const trackEvent = ({ element, action, pathOverride, data, alsoSendTo }: TrackEventProps): void => {
  const eventName = `${pathOverride || generatePageNameFromCurrentLocation()}_${element}_${action}`;

  if (alsoSendTo?.intercom && typeof Intercom !== 'undefined') {
    Intercom(
      'trackEvent',
      typeof alsoSendTo?.intercom === 'object' ? alsoSendTo?.intercom?.name : eventName,
      typeof alsoSendTo?.intercom === 'object' ? alsoSendTo?.intercom?.data : undefined,
    );
  }

  if (Hotjar.isReady()) {
    Hotjar.event(eventName);
  }

  analytics.track(eventName, data);
  rudderanalytics.track(eventName, data);
};

/**
 * Triggers an analytics event for an error.
 *  *
 * @returns Promise Resolved if the call was successful, failed otherwise
 */
export const trackError = async (errorMessage: string, data?: apiObject): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      rudderanalytics.track('error', { ...data, pagePath: window.location.pathname, errorMessage }, undefined, resolve);
    } catch (e) {
      reject(e);
    }
  });
};
